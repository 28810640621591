@use '../../styles/_settings' as set;

.mainPage {
	font-family: 'Trocchi, Arial, Helvetica, sans-serif';
	font-style: italic;

	header {
		@include set.imageBackground();

		// Tackles extreme aspect-ratios in mobile view
		@media screen and (max-height: 38rem) and (min-width: 0) {
			height: 38rem;
		}
		@media screen and (max-height: 24rem) and (min-width: set.$br_601) {
			height: 24rem;
		}

		h1 {
			@include set.flex(center, center);
			margin: 0 15%;
			//padding-top: 15vh; //-- Remove if animation is full
			transform: translateY(15vh);
			text-align: center;
			font-size: 2.4rem;
			font-style: italic;
			line-height: 1.75;

			animation-name: titleAnimation;
			animation-duration: 4s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;

			@media screen and (min-width: set.$br_769) {
				//padding-top: 25vh; //-- Remove if animation is full
				//--
				padding-top: 3.5vh;
				//--
				transform: translateY(15vh);
				font-size: 3rem;
			}
			// Tackles portrait orientation screens
			@media screen and (min-width: set.$br_769) and (min-aspect-ratio: 2 / 1) {
				padding-top: 0;
			}
			@media screen and (min-width: set.$br_1920) {
				font-size: 2.67vw;
			}
		}

		.buttonSection {
			width: 65%;
			margin: 24vh auto 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			animation-name: buttonAnimation;
			animation-duration: 4s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;

			@media screen and (min-width: set.$br_601) {
				width: 87%;
			}

			@media screen and (min-width: set.$br_769) {
				//--
				//margin: 42.5vh auto 0;
				margin: 40vh auto 0;
				//--
				justify-content: space-between;
			}

			@media screen and (min-width: set.$br_1025) {
				width: 75%;
			}

			@media screen and (min-width: set.$br_1281) {
				width: 60%;
			}
			@media screen and (min-width: set.$br_1920) {
				width: 55%;
			}

			// Tackles portrait orientation screens
			@media screen and (max-aspect-ratio: 4 / 5) {
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				margin: 24vh auto 0;
			}

			button {
				@include set.buttonPrimary();
				margin: 1.5rem;

				@media screen and (min-width: set.$br_769) {
					margin: 0;
				}

				// Tackles portrait orientation screens
				@media screen and (min-width: set.$br_769) and (max-aspect-ratio: 4 / 5) {
					margin: calc(1.5rem + 0.5vh);
				}
			}
		}
	}

	main {
		.introSection {
			background-color: set.$Pink2;

			h2 {
				@include set.h2();
			}

			.introContainer {
				display: block;
				padding-bottom: 0.01rem;

				@media screen and (min-width: set.$br_769) {
					display: flex;
				}

				.leftCol,
				.rightCol {
					@media screen and (min-width: set.$br_769) {
						width: 50%;
					}
				}

				.leftCol {
					@include set.flex(center, center);

					@media screen and (min-width: set.$br_769) {
						margin: 0 1.5rem 3rem 3rem;
					}
					@media screen and (min-width: set.$br_1920) {
						margin: 0 1.25vw 2.5vw 2.5vw;
					}

					.card {
						width: 100%;
						display: flex;
						flex-direction: column;
						background-color: white;

						&:hover {
							background-color: set.$Pink2_hover;
						}

						h3,
						h4,
						hr {
							@include set.flex(center, center);
						}

						h3 {
							@include set.h2();
							color: set.$Pink1;
							padding: 2.25rem 0 0.75rem;

							@media screen and (min-width: set.$br_1920) {
								padding: 1.875vw 0 0.625vw;
							}
						}

						h4 {
							@include set.h3();
							margin: 0.75rem auto 2.25rem;

							@media screen and (min-width: set.$br_1920) {
								margin: 0.625vw auto 1.875vw;
							}
						}

						hr {
							width: min(70%, 17rem);
							height: 1px;
							background-color: black;
							opacity: 0.5;

							@media screen and (min-width: set.$br_1920) {
								width: min(70%, 14.167vw);
							}
						}
					}
				}

				.rightCol {
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: 3rem;

					@media screen and (min-width: set.$br_769) {
						margin: 0 3rem 3rem 1.5rem;
					}
					@media screen and (min-width: set.$br_1920) {
						margin: 0 2.5vw 2.5vw 1.25vw;
					}

					p {
						@include set.body();
						margin: 0;
					}

					p:first-of-type {
						margin-bottom: 1rem;

						@media screen and (min-width: set.$br_1920) {
							margin-bottom: 0.83vw;
						}
					}
				}
			}
		}

		.treatmentsSection {
			h2 {
				@include set.h2();
			}

			.highlightedTreatmentsContainer {
				.highlightedTreatmentItem_1,
				.highlightedTreatmentItem_2,
				.highlightedTreatmentItem_3 {
					margin-bottom: 3rem;

					@media screen and (min-width: set.$br_1281) {
						margin-bottom: 4rem;
					}

					h3 {
						@include set.listElement();
						@include set.flex(center, center);
						font-weight: normal;
						margin: 0 3rem 1.5rem;

						@media screen and (min-width: set.$br_1281) {
							margin: 0 3rem 3rem;
						}

						&:hover {
							@media screen and (min-width: set.$br_769) {
								color: set.$Pink1;
								margin-left: 4.5rem;
							}
							@media screen and (min-width: set.$br_1281) {
								margin-left: 6.5%;
							}
							@media screen and (min-width: set.$br_1920) {
								margin-left: 4.5%;
							}
						}
					}

					.highlightedTreatment {
						min-height: 32.733vw;

						@media screen and (min-width: set.$br_1281) {
							@include set.flex(flex-start, center);
						}

						.treatmentDescription {
							padding: 0 3rem;

							@media screen and (min-width: set.$br_1281) {
								width: 30%;
								padding: 0 4rem 0 0;
								order: 2;
							}

							@media screen and (min-width: set.$br_1920) {
								padding: 0 3vw 0 0;
							}

							p {
								@include set.body();
							}
						}

						img {
							width: 100%;

							@media screen and (min-width: set.$br_1281) {
								width: 70%;
								order: 1;
							}
						}
					}
				}

				.highlightedTreatmentItem_1 {
					.highlightedTreatment {
						@media screen and (min-width: set.$br_1281) {
							background-color: set.$Bg_aha;
						}
					}
				}

				.highlightedTreatmentItem_2 {
					.highlightedTreatment {
						@media screen and (min-width: set.$br_1281) {
							background-color: set.$Bg_3Peptides;
						}
					}

					p {
						@media screen and (min-width: set.$br_1281) {
							color: white;
						}
					}
				}

				.highlightedTreatmentItem_3 {
					.highlightedTreatment {
						@media screen and (min-width: set.$br_1281) {
							background-color: set.$Bg_Oxygen;
						}
					}
				}
			}

			.treatmentsContainer {
				margin-bottom: 3rem;
				display: flex;
				flex-direction: column;

				@media screen and (min-width: set.$br_769) {
					flex-direction: row;
					justify-content: center;
				}
				@media screen and (min-width: set.$br_1920) {
					margin-bottom: 1.392vw;
				}

				.leftCol,
				.rightCol {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					margin: 0;
					padding: 0;

					@media screen and (min-width: set.$br_769) {
						width: 50%;
					}

					// Treatment list elements
					li.listElement {
						@include set.listElement();
						width: 15.5rem;
						display: flex;
						align-items: flex-start;
						margin: 1rem 0;

						@media screen and (min-width: set.$br_1920) {
							width: 12.917vw;
						}
					}
				}

				.leftCol {
					@media screen and (min-width: set.$br_769) {
						align-items: flex-end;
					}

					// Treatment list elements (left)
					li.listElement {
						user-select: none;

						@media screen and (min-width: set.$br_769) {
							margin-right: 14%;
						}

						&:hover {
							color: set.$Pink1;
							margin-left: 8.5%;

							@media screen and (min-width: set.$br_481) {
								margin-left: 5%;
							}
							@media screen and (min-width: set.$br_769) {
								margin-right: 17%;
							}
						}
					}
				}

				.rightCol {
					@media screen and (min-width: set.$br_769) {
						align-items: flex-start;
					}

					// Treatment list elements (right)
					li.listElement {
						@media screen and (min-width: set.$br_769) {
							width: 20rem;
							margin-left: 16%;
						}
						@media screen and (min-width: set.$br_1920) {
							width: 16.67vw;
						}

						&:hover {
							color: set.$Pink1;
							margin-left: 9.5%;

							@media screen and (min-width: set.$br_481) {
								margin-left: 5.5%;
							}
							@media screen and (min-width: set.$br_769) {
								margin-left: 19%;
							}
						}
					}
				}
			}
		}

		.contactSection {
			display: flex;
			flex-wrap: wrap;
			position: relative;

			.contactImg,
			.contactText {
				width: 100%;

				@media screen and (min-width: set.$br_769) {
					width: 50%;
				}
			}

			.contactImg {
				background-image: url('../../styles/assets/pictures/contact02_1000.jpg');
				background-size: cover;
				background-position: center;
			}

			.contactText {
				padding: 1.25rem 0 2.25rem 0;
				background-color: set.$Pink4;
				font-size: 1.4rem;

				@media screen and (min-width: set.$br_769) {
					padding: 4rem 0;
					font-size: 1.75rem;
				}
				@media screen and (min-width: set.$br_1920) {
					padding: 3.33vw 0;
					font-size: 1.458vw;
				}

				.odd,
				.even {
					display: flex;

					h3 {
						@include set.h2();
					}
				}

				.odd {
					margin: 1rem 20% 1.25rem 15%;

					@media screen and (min-width: set.$br_769) {
						margin: 1.25rem 20% 1.25rem 15%;
					}
				}

				.even {
					justify-content: flex-end;
					margin: 0 15% 0 20%;
				}

				// Phone number and address
				a {
					text-decoration: none;
					color: black;
					transition: color 0.3s ease-in-out;

					&:hover {
						color: set.$Pink1;
					}
				}
			}

			.toTopBtn {
				@include set.buttonBase();
				background-color: rgba(255, 255, 255, 0.5);
				transition: background-color 0.5s ease-in-out;

				position: absolute;
				left: 50%;
				bottom: 2rem;
				transform: translateX(-50%);

				&:hover {
					cursor: pointer;
					background-color: rgba(255, 255, 255, 0.75);
				}

				&:after {
					content: '';
					position: absolute;
					left: 20%;
					bottom: 0.5rem;
					width: 0;
					height: 3px;
					background-color: set.$Pink1;
					transition: width 0.5s linear;
				}

				&:hover::after {
					width: 60%;
				}
			}
		}
	}

	footer {
		.impressBtnSection {
			@include set.flex(center, center);
			background-color: set.$Pink4;

			button {
				@include set.buttonSecondary();
				margin: 0.6rem auto;
				border: none;
				font-size: 0.8rem;

				&:after {
					@media screen and (min-width: set.$br_769) {
						bottom: 1.25rem;
					}
				}

				@media screen and (min-width: set.$br_769) {
					margin: 0;
					min-height: 5rem;
				}
			}
		}
	}
}

//--
.test {
	width: 0;
	height: 0;
	background-image: url('../../styles/assets/pictures/bg_desktop01_90deg.jpg');
}
//

@keyframes titleAnimation {
	0% {
		opacity: 0;

		@media screen and (min-width: set.$br_769) {
		}
	}
	100% {
		opacity: 1;
		letter-spacing: 2px;

		@media screen and (min-width: set.$br_769) {
		}
	}
}

@keyframes buttonAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
