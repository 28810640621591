@use '_colors' as set;

/* Breakpoints */
$br_481: 481px;
$br_601: 601px;
$br_769: 769px;
$br_1025: 1025px;
$br_1281: 1281px;
$br_1920: 1920px;

/* //--Base page margin */
@mixin pageMargin() {
	margin: 0 14%;
	@media screen and (max-width: $tablet) {
		margin: 0 8%;
	}
	@media screen and (max-width: $mobile) {
		margin: 0 4.8%;
	}
}

/* Text base */
@mixin textBase() {
	font-family: 'Trocchi, Arial, Helvetica, sans-serif';
	font-style: italic;
}

/* h1 */
@mixin h1($margin: false, $padding: false) {
	@include textBase();
	font-size: 2rem;
	font-weight: 700;
	text-align: center;

	@if ($margin) {
		margin: 3rem 0;
		padding: 0;

		@media screen and (min-width: $br_769) {
			margin: 4.5rem 0;
		}
		@media screen and (min-width: $br_1920) {
			margin: 3.75vw 0;
		}
	} @else if ($padding) {
		margin: 0;
		padding: 3rem 0;

		@media screen and (min-width: $br_769) {
			padding: 4.5rem 0;
		}
		@media screen and (min-width: $br_1920) {
			padding: 3.75vw 0;
		}
	}

	@media screen and (min-width: $br_1281) {
		font-size: 2.2rem;
	}
	@media screen and (min-width: $br_1920) {
		font-size: 1.875vw;
	}
}

/* h2 */
@mixin h2() {
	@include textBase();
	//--@include centered();
	@include flex(center, center);
	font-size: 1.6rem;
	font-weight: 700;
	margin: 0;
	padding: 2.67rem 0;

	@media screen and (min-width: $br_769) {
		font-size: 2rem;
	}
	@media screen and (min-width: $br_1920) {
		font-size: 1.67vw;
		padding: 2.225vw 0;
	}
}

/* h3 */
@mixin h3() {
	@include textBase();
	font-weight: 700;
	font-size: 1.2rem;

	@media screen and (min-width: $br_769) {
		font-size: 1.5rem;
	}
	@media screen and (min-width: $br_1920) {
		font-size: 1.25vw;
	}
}

/* h4 */
@mixin h4() {
	@include textBase();
	margin: 1.46rem 0;
	font-weight: 700;
	font-size: 1.1rem;

	@media screen and (min-width: $br_769) {
		margin: 1.73rem 0;
		font-size: 1.3rem;
	}
	@media screen and (min-width: $br_1920) {
		margin: 1.44vw 0;
		font-size: 1.083vw;
	}
}

/* //--Caption */
@mixin caption() {
	@include textBase();
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
}

/* Body */
@mixin body() {
	@include textBase();
	font-weight: 400;
	font-size: 1rem;

	@media screen and (min-width: $br_769) {
		font-size: 1.25rem;
	}
	@media screen and (min-width: $br_1920) {
		font-size: 1.042vw;
	}
}

/* //--Text link */
@mixin textLink() {
	@include textBase();
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

/* Background */
@mixin imageBackground() {
	width: 100%;
	height: 100vh;
	background-image: url('../../styles/assets/pictures/bg_desktop01.jpg');
	background-size: cover;
	background-position: center;
}

/* Buttons */
@mixin buttonBase() {
	width: 10.5rem;
	display: inline-block;
	position: relative;
	padding: 0.9rem;
	background: transparent;
	font-size: 0.76rem;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;

	&:hover {
		cursor: pointer;
	}

	@media screen and (min-width: $br_769) {
		width: 13rem;
		padding: 1.1rem;
		font-size: 0.95rem;
	}
	@media screen and (min-width: $br_1920) {
		width: 11.67vw;
		padding: 0.91vw;
		font-size: 0.85vw;
	}
}

@mixin buttonPrimary() {
	@include buttonSecondary();
	border: 1px solid black;
	//transition: border 0.2s ease-in-out;

	@media screen and (min-width: $br_769) {
		transition: border 0.5s ease-in-out;
	}

	&:hover {
		border: 1px solid rgba(0, 0, 0, 0);
	}
}

@mixin buttonSecondary() {
	@include buttonBase();

	&:after {
		content: '';
		position: absolute;
		left: 20%;
		bottom: 0.5rem;
		width: 0;
		height: 3px;
		background-color: set.$Pink1;
		//transition: width 0.2s linear;

		@media screen and (min-width: $br_769) {
			transition: width 0.5s linear;
		}
		@media screen and (min-width: $br_1920) {
			bottom: 0.417vw;
		}
	}

	&:hover::after {
		width: 60%;
	}
}

@mixin buttonBackToMain() {
	@include buttonPrimary();
	width: 11rem;

	@media screen and (min-width: $br_769) {
		width: 13rem;
		padding: 1rem;
		font-size: 0.85rem;
	}
	@media screen and (min-width: $br_1281) {
		width: 14rem;
		padding: 1.1rem;
		font-size: 0.95rem;
	}
	@media screen and (min-width: $br_1920) {
		width: 14vw;
		padding: 0.91vw;
		font-size: 0.85vw;
	}
}

/* Flexbox */
@mixin flex($justifyContent: null, $alignItems: null, $flexDirection: null, $flexWrap: null) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $flexDirection;
	flex-wrap: $flexWrap;
}

/* List element */
@mixin listElement() {
	font-size: 1.2rem;
	list-style: none;
	transition: margin 0.4s linear;
	user-select: none;

	@media screen and (min-width: $br_769) {
		font-size: 1.5rem;
	}
	@media screen and (min-width: $br_1920) {
		margin: 0.83vw 0;
		font-size: 1.25vw;
	}

	&::before {
		content: '\25A0';
		display: inline-block;
		color: set.$Pink3;
		transform: rotate(45deg);
		margin-right: 0.6rem;
	}
}
