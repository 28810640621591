@use '../../styles/_settings' as set;

.priceListPage {
	width: 100%;
	height: 100%;
	background-image: url('../../styles/assets/pictures/bg_desktop01_90deg.jpg');
	background-position: top;
	background-size: contain;
	background-repeat: repeat-y;
	font-family: 'Trocchi, Arial, Helvetica, sans-serif';
	font-style: italic;

	@media screen and (min-width: set.$br_1281) {
		background-position: bottom;
		background-size: cover;
	}

	main {
		h1 {
			@include set.h1($padding: true);
			
			span {
				font-weight: normal;
			}
		}

		table {
			width: 90%;
			margin: 0 auto;
			padding: 1rem 3%;
			font-size: 1.2rem;
			border-collapse: separate;
			border-spacing: 0;
			background-color: rgba($color: white, $alpha: 0.375);

			@media screen and (min-width: set.$br_481) {
				padding: 1.5rem 3%;
			}
			@media screen and (min-width: set.$br_769) {
				width: 75%;
				padding: 2.5rem 3rem;
				font-size: 1.5rem;
			}
			@media screen and (min-width: set.$br_1920) {
				padding: 2.083vw 2.5vw;
				font-size: 1.25vw;
			}

			thead {
				th {
					padding-bottom: 1.5rem;
					font-size: 1.5rem;
					text-decoration: underline;

					@media screen and (min-width: set.$br_481) {
						padding-bottom: 2rem;
					}
                    @media screen and (min-width: set.$br_769) {
						padding-bottom: 2.5rem;
						font-size: 1.75rem;
					}
					@media screen and (min-width: set.$br_1920) {
						padding-bottom: 2.083vw;
						font-size: 1.46vw;
					}
				}
			}

			tbody {
				.tableRow {
					user-select: none;
					//--
					display: flex;
					align-items: center;
					justify-content: space-between;
					//--

					&:hover {
						background-color: rgba($color: white, $alpha: 0.45);
					}

					td {
						padding: 0.5rem;

						@media screen and (min-width: set.$br_481) {
							padding: 1rem;
						}
						@media screen and (min-width: set.$br_1920) {
							padding: 0.83vw;
						}
					}

					/* Price cells */
					td:nth-of-type(2) {
						min-width: 5rem;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-end;
						text-align: right;

						@media screen and (min-width: set.$br_769) {
							width: 12rem;
						}
						@media screen and (min-width: set.$br_1920) {
							width: 10vw;
						}
					}
				}
			}
		}
	}

    footer {
		@include set.flex(center, center);
		padding: 3rem 0 4rem;

		@media screen and (min-width: set.$br_769) {
			padding: 4.5rem 0;
		}
		@media screen and (min-width: set.$br_1920) {
			padding: 3.75vw 0;
		}

		button {
			@include set.buttonBackToMain();
			margin: 0 auto;
			text-decoration: none !important;
		}
	}
}
