@use '../../styles/_settings' as set;

.errorPage {
	main {
		@include set.imageBackground();
        @include set.flex(center, center, column);

		// Tackles extreme aspect-ratios in mobile view
		@media screen and (max-height: 38rem) and (min-width: 0) {
			height: 38rem;
		}
		@media screen and (max-height: 24rem) and (min-width: set.$br_601) {
			height: 24rem;
		}

        h1 {
            @include set.h1();
            max-width: 80%;
            margin: 0 auto 7rem;

            @media screen and (min-width: set.$br_1920) {
				margin: 0 auto 7vw;
			}

            span {
                font-weight: normal;
            }
        }

        button {
            @include set.buttonPrimary();
            width: 11rem;

            @media screen and (min-width: set.$br_769) {
                width: 14rem;
            }
            @media screen and (min-width: set.$br_1920) {
				width: 13vw;
			}
        }
    }
}