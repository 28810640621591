$Black: #1d1d1b;
$Bg_3Peptides: #9396c9;
$Bg_aha: #e2ddd7;
$Bg_Oxygen: #c5eaf3;
$Pink1: rgb(255, 112, 148);
$Pink2: rgb(245, 239, 241);
$Pink3: rgb(250, 175, 196);
$Pink4: rgb(246, 230, 230);
$Pink2_hover: rgb(252, 250, 251);
$GreyBackground: linear-gradient(180deg, #ebebeb 0%, rgba(245, 245, 245, 0.92) 68.03%);
$GreyBackground2: linear-gradient(180deg, #ebebeb 0%, rgba(245, 245, 245, 0) 68.03%);
$GreyBackground3: linear-gradient(180deg, rgba(44, 186, 231, 0.04) 50.49%, rgba(4, 91, 172, 0.048) 100%);
