@use '../../styles/_settings' as set;

.impressPage {
    @include set.imageBackground();
	overflow: auto;
	font-family: 'Trocchi, Arial, Helvetica, sans-serif';
	font-style: italic;

	@media screen and (max-height: 24rem) and (min-width: set.$br_601) {
		height: 24rem; //-- change
	}


	main {
		display: flex;
        flex-direction: column;
        align-items: center;

		h1 {
			@include set.h1($margin: true);
		}

		.impressContainer {
            width: 80%;
			padding: 3rem;
			background-color: rgba($color: white, $alpha: 0.375);

            @media screen and (min-width: set.$br_481) {
                width: 20rem;
            }
            @media screen and (min-width: set.$br_769) {
                width: 30rem;
            }
			@media screen and (min-width: set.$br_1920) {
                width: 25vw;
            }

			.impressSection {
				@include set.flex(center, center);
				flex-direction: column;

                h4 {
                    @include set.h4();
                }

                p {
                    width: 100%;
                    margin: 0.25rem;
                    display: flex;
                    justify-content: space-between;

					@media screen and (min-width: set.$br_1920) {
						margin: 0.208vw;
					}

                    span {
                        font-size: 1rem;
                        font-weight: normal;
    
                        @media screen and (min-width: set.$br_769) {
                            font-size: 1.25rem;
                        }
						@media screen and (min-width: set.$br_1920) {
                            font-size: 1.042vw;
                        }
                    }

					span.title {
						text-align: left;
					}

					span.data {
						text-align: right;
					}
                }
			}
		}
	}

	footer {
		margin: 4.5rem auto;
        display: flex;
        justify-content: center;

		@media screen and (min-width: set.$br_1920) {
			margin: 3.75vw 0;
		}

		button {
			@include set.buttonBackToMain();
		}
	}
}
